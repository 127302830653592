<template>
  <div>
    <!-- Page Heading -->
    <div class="ps-form__header">
      <h3>Edit Setting</h3>
    </div>

    <!-- Content Row -->
    <SettingForm :setting-id="$route.params.id"></SettingForm>

  </div>
</template>

<script>
import SettingForm from "./SettingForm";
export default {
  name: "EditSetting",
  components: {SettingForm},
}
</script>

<style scoped>

</style>